<template>
  <div class="app-wrap">
    <el-container class="app-container">
      <el-header height="auto">
        <div class="fr">
          <el-button
            v-if="defaultSetting"
            :icon="Back"
            @click="defaultSetting = false"
          >取消</el-button>
          <el-dropdown
            v-else
            type="primary"
            split-button
            @click="editLevel()"
            @command="defaultSetting = true"
          >
            <el-icon><circle-plus></circle-plus></el-icon>
            添加等级
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>设置默认等级</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-header>
      <el-main>
        <el-table :data="tableData">
          <el-table-column label="序号" type="index" align="right"></el-table-column>
          <el-table-column label="等级" prop="name"></el-table-column>
          <el-table-column label="等级分类">
            <template #default="{ row }">
              {{ row.level_category.name }}
            </template>
          </el-table-column>
          <el-table-column label="经验值" align="center" width="200">
            <template #default="{ row }">
              {{ row.start_exp }}
              -
              {{ row.end_exp }}
            </template>
          </el-table-column>
          <el-table-column label="上门费" align="center">
            <template #default="{ row }">
              {{ getRatio(row.door_ratio) }}
            </template>
          </el-table-column>
          <el-table-column label="服务费" align="center">
            <template #default="{ row }">
              {{ getRatio(row.service_ratio) }}
            </template>
          </el-table-column>
          <el-table-column label="配件费" align="center">
            <template #default="{ row }">
              {{ getRatio(row.part_ratio) }}
            </template>
          </el-table-column>
          <el-table-column label="默认等级" align="center" width="68">
            <template #default="{ row }">
              <el-tag v-if="row.is_default" type="success">是</el-tag>
            </template>
          </el-table-column>
          <el-table-column v-if="defaultSetting" label="操作" align="center" width="118">
            <template #default="{ row }">
              <el-button
                type="primary"
                :disabled="row.is_default === 1"
                :icon="Select"
                @click="setDefault(row.id)"
              >设为默认</el-button>
            </template>
          </el-table-column>
          <el-table-column v-else label="操作" align="center" width="118">
            <template #default="{ row }">
              <el-button
                type="text"
                :icon="Edit"
                @click="editLevel(row.id)"
              >编辑</el-button>
              <el-button
                type="text"
                class="color-danger"
                :icon="Delete"
                @click="remove(row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
  </div>
  <EditDialog ref="editDialog" @submit="getList"></EditDialog>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { ElMessageBox } from 'element-plus';
import {
  CirclePlus,
  Edit,
  Delete,
  Select,
  Back,
} from '@element-plus/icons-vue';
import EditDialog from './edit.vue';
import request from '@/hooks/request';

const tableData = ref([]);
const defaultSetting = ref(false);

function getRatio(ratio) {
  const re = Number(ratio);
  if (re) {
    return `${re * 100}%`;
  }
  return '-';
}

function getList() {
  request.get('/admin/engineer/level').then((data) => {
    tableData.value = data;
  });
}

const editDialog = ref();

function editLevel(levelId) {
  editDialog.value.open(levelId);
}

function setDefault(levelId) {
  request.post(`/admin/engineer/level/setDefault/${levelId}`, {
    is_default: 1,
  }).then(() => {
    defaultSetting.value = false;
    getList();
  });
}

function remove(level) {
  ElMessageBox.confirm(`删除等级：${level.name}，是否继续？`, '提示', {
    type: 'warning',
    confirmButtonText: '确定',
    cancelButtonText: '取消',
  }).then(() => {
    request.delete(`/admin/engineer/level/${level.id}`).then(() => {
      getList();
    });
  });
}

onMounted(() => {
  getList();
});
</script>
