<template>
  <tj-dialog
    v-model="visible"
    :title="title"
    @close="onClose"
  >
    <el-form ref="formRef" label-width="auto" :model="form" :rules="rules">
      <el-form-item label="等级名称：" prop="name">
        <el-input v-model.trim="form.name"></el-input>
      </el-form-item>
      <el-form-item label="等级分类：" prop="level_category_id">
        <el-select v-model="form.level_category_id" class="wf">
          <el-option
            v-for="item in levelCategoryList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="经验值：" class="flex">
        <el-col :span="11">
          <el-input v-model.number="form.start_exp"></el-input>
        </el-col>
        <el-col :span="2" class="tac"> - </el-col>
        <el-col :span="11">
          <el-input v-model.number="form.end_exp"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="上门费：">
        <el-input-number v-model.number="doorRatio" :min="0" :max="100"></el-input-number>
        %
      </el-form-item>
      <el-form-item label="服务费：">
        <el-input-number v-model.number="serviceRatio" :min="0" :max="100"></el-input-number>
        %
      </el-form-item>
      <el-form-item label="配件费：">
        <el-input-number v-model.number="partRatio" :min="0" :max="100"></el-input-number>
        %
      </el-form-item>
      <el-form-item label="设为默认等级：">
        <el-checkbox
          v-model="form.is_default"
          :true-label="1"
          :false-label="0"
          :disabled="isDefault"
        ></el-checkbox>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </tj-dialog>
</template>

<script setup>
import { ref, computed, nextTick } from 'vue';
import { useDialog } from '@tj/ui';
import request from '@/hooks/request';

const isDefault = ref(false);

const emit = defineEmits(['submit']);

const defaultLevelInfo = {
  id: 0,
  name: '',
  level_category_id: null,
  start_exp: 0,
  end_exp: 0,
  door_ratio: 0,
  service_ratio: 0,
  part_ratio: 0,
  is_default: 0,
};

const form = ref({ ...defaultLevelInfo });
const formRef = ref();

const doorRatio = computed({
  get() {
    return form.value.door_ratio * 100;
  },
  set(val) {
    form.value.door_ratio = val / 100;
  },
});

const serviceRatio = computed({
  get() {
    return form.value.service_ratio * 100;
  },
  set(val) {
    form.value.service_ratio = val / 100;
  },
});

const partRatio = computed({
  get() {
    return form.value.part_ratio * 100;
  },
  set(val) {
    form.value.part_ratio = val / 100;
  },
});

const rules = {
  name: { required: true, message: '等级名称不能为空' },
  level_category_id: {
    required: true,
    message: '等级分类不能为空',
  },
};

const title = computed(() => {
  if (form.value.id) {
    return '编辑等级';
  }
  return '添加等级';
});

const levelCategoryList = ref([]);
function getLevelCategory() {
  request.get('/admin/engineer/levelCategory').then((data) => {
    levelCategoryList.value = {
      ...data,
      door_ratio: Number(data.door_ratio),
      service_ratio: Number(data.service_ratio),
      part_ratio: Number(data.part_ratio),
    };
  });
}

const {
  visible,
  open,
  close,
  onOpen,
} = useDialog();
onOpen((id) => {
  getLevelCategory();
  if (id) {
    request.get(`/admin/engineer/level/${id}`).then((data) => {
      form.value = data;
      if (data.is_default) {
        isDefault.value = true;
      }
    });
  } else {
    form.value = { ...defaultLevelInfo };
  }
  nextTick(() => {
    formRef.value.clearValidate();
  });
});
function onClose() {
  form.value = { ...defaultLevelInfo };
  isDefault.value = false;
}

function submit() {
  formRef.value.validate((valid) => {
    if (valid) {
      const { id } = form.value;
      if (id) {
        request.patch(`/admin/engineer/level/${id}`, form.value).then(() => {
          emit('submit');
          close();
        });
      } else {
        request.post('/admin/engineer/level', form.value).then(() => {
          emit('submit');
          close();
        });
      }
    }
  });
}

defineExpose({
  open,
});
</script>
